import {AuthService} from "../../shared/auth/auth.service";
import {Component} from '@angular/core';
import {Direction} from '@angular/cdk/bidi';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

@Component({
    selector: 'nivus-projects',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {

    constructor(
        public authService: AuthService) {
    }
}


