import {AuthConfig} from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
    issuer: 'https://identity.nivusweb.com',
    clientId: 'Nivus.Devices.Frontend',
    scope: 'openid profile email roles offline_access device.admin',
    responseType: 'code',

    redirectUri: document.baseURI + 'index.html',
    postLogoutRedirectUri: document.baseURI + 'index.html',

    silentRefreshRedirectUri: document.baseURI + 'silent-renew.html',
    silentRefreshMessagePrefix: '?',
    useSilentRefresh: true,

    sessionChecksEnabled: true,
    sessionCheckIntervall: 10 * 1000,

    preserveRequestedRoute: true
};
