import { Observable } from 'rxjs';
import {DeviceHttpService} from '../device-http.service';

export abstract class BaseService {
    abstract get apiEndpoint(): string;

    constructor(protected http: DeviceHttpService) {
    }

    get(endpoint: string, options?: any): Observable<any> {
        return this.http.get(`${endpoint}`, options);
    }

    post(endpoint: string, data: any, options?: any): Observable<any> {
        return this.http.post(`${endpoint}`, data, options);
    }

    put(endpoint: string, data: any, options?: any): Observable<any> {
        return this.http.put(`${endpoint}`, data, options);
    }

    delete(endpoint: string, options?: any): Observable<any> {
        return this.http.delete(`${endpoint}`, options);
    }
}
