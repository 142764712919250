import { Observable } from 'rxjs';
import { BaseData } from './base';
import {DeviceType} from './devices';

export interface Statistics {
    deviceStatistics: DeviceStatistics[];
    licenseStatistics: LicenseStatistics[];
    deviceCount: number;
    licenseCount: number;
}

export interface DeviceStatistics {
    deviceTypeShort: string,
    deviceType: DeviceType;
    count: number;
}

export interface LicenseStatistics {
    licenseArticle: string;
    count: number;
}

export abstract class StatisticsData extends BaseData<Statistics, number> {
    abstract getStatistics(from: string, to: string): Observable<Statistics>;
}

