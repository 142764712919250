import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SimCard, SimData, Tariff} from '../model/simcards';
import {DeviceHttpService} from '../device-http.service';
import {BaseService} from './base.service';

@Injectable()
export class SimService extends BaseService implements SimData {
    get apiEndpoint(): string {
        return 'simcard';
    }

    constructor(http: DeviceHttpService) {
        super(http);
    }

    getSimCards(): Observable<SimCard[]> {
        return this.http.get(this.apiEndpoint);
    }

    getSimCard(iccid: string): Observable<SimCard> {
        return this.http.get(`${this.apiEndpoint}/${iccid}`);
    }

    getTariffs(): Observable<Tariff[]> {
        return this.http.get(`${this.apiEndpoint}/tariff`);
    }

    activate(iccid: string, tariff: string): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${iccid}/tariff`, {tariff});
    }

    deactivate(iccid: string): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${iccid}/tariff`);
    }

    reload(iccid: string): Observable<SimCard> {
        return this.http.get(`${this.apiEndpoint}/${iccid}/reload`);
    }

    wakeUp(iccId: string): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${iccId}/wakeup`, null);
    }
}
