import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {DeviceHttpService} from '../device-http.service';
import {FileData} from '../model/files';
import {CustomerFile} from '../model/files';

@Injectable()
export class FilesService extends BaseService implements FileData {
    constructor(http: DeviceHttpService) {
        super(http);
    }

    get apiEndpoint(): string {
        return 'file';
    }

    getFiles(): Observable<CustomerFile[]> {
        return this.http.get(this.apiEndpoint);
    }

    uploadFile(file: File, users: string[], customerId: string): Observable<CustomerFile> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("customerId", customerId);
        for(let i = 0; i < users?.length; i++) {
            formData.append("users", users[i]);
        }
        return this.http.post(`${this.apiEndpoint}`, formData,{
            reportProgress: true,
            observe: "events"
        });
    }

    downloadFile(id: string): Observable<Blob> {
        return this.http.get(`${this.apiEndpoint}/${id}/download`, {
            responseType: 'blob',
        });
    }

    deleteFile(id: string): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${id}`);
    }

    updateFile(file: CustomerFile, id: string): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${id}`, file);
    }

    getQuota(customerId: string): Observable<number> {
        return this.http.get(`${this.apiEndpoint}/quota/${customerId}`);
    }
}
