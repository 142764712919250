import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {distinctUntilChanged, Observable} from 'rxjs';
import {filter, map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {UserRole, UserType} from '../backend/device/model/users';
import {authConfig} from './auth.config';
import {CurrentUserService} from '../components/role-select/current-user.service';
import {Router} from '@angular/router';
import {Customer, CustomersData} from "../backend/device/model/customers";

@Injectable()
export class AuthService {
    currentUserRole$: Observable<UserRole>;
    currentUserType$: Observable<UserType>;
    isCustomerAdmin$: Observable<boolean>;
    isStructureAdmin$: Observable<boolean>;
    isCustomerUser$: Observable<boolean>;
    isStructureUser$: Observable<boolean>;

    isLoggedIn$: Observable<boolean>;
    claims$: Observable<Claims>;

    currentUserEmail$: Observable<string>;
    isMqttAdmin$: Observable<boolean>;
    isSimAdmin$: Observable<boolean>;
    isDevicesUser$: Observable<boolean>;
    isDevicesAdmin$: Observable<boolean>;
    isProxyUser$: Observable<boolean>;
    isDataKioskUser$: Observable<boolean>;
    currentCustomer$: Observable<Customer>;
    isServiceUser$: Observable<boolean>;
    isLicenseAdmin$: Observable<boolean>;


    constructor(private authService: OAuthService, private userService: CurrentUserService, private customerService: CustomersData, private router: Router) {
        this.currentUserRole$ = this.userService.selectedRole.pipe(distinctUntilChanged());

        this.currentUserType$ = this.currentUserRole$
            .pipe(
                map(customerRole => customerRole.role),
                shareReplay(1)
            );

        this.currentCustomer$ = this.currentUserRole$
            .pipe(
                switchMap(role => this.customerService.getCustomer(role.customerId)),
                shareReplay(1)
            );

        this.isLoggedIn$ = this.authService.events
            .pipe(
                filter(event => event.type === 'token_received'),
                map(event => this.authService.hasValidAccessToken()),
                startWith(this.authService.hasValidAccessToken()),
                shareReplay(1)
            );
        this.isLoggedIn$.subscribe();

        this.claims$ = this.authService.events
            .pipe(
                filter(event => event.type === 'user_profile_loaded'),
                map(event => this.authService.getIdentityClaims() as Claims),
                shareReplay(1)
            );
        this.claims$.subscribe();

        this.currentUserEmail$ = this.claims$
            .pipe(
                map(claims => claims.email),
                shareReplay(1)
            );

        this.isDevicesUser$ = this.claims$
            .pipe(
                map(claims => claims.role?.indexOf('DevicesUser') > -1),
                shareReplay(1)
            );
        this.isDevicesAdmin$ = this.claims$
            .pipe(
                map(claims => claims.role?.indexOf('DevicesAdmin') > -1),
                shareReplay(1)
            );

        this.isMqttAdmin$ = this.claims$
            .pipe(
                map(claims => claims.role?.indexOf('MqttAdmin') > -1),
                shareReplay(1)
            );

        this.isSimAdmin$ = this.claims$
            .pipe(
                map(claims => claims.role?.indexOf('SimAdmin') > -1),
                shareReplay(1)
            );

        this.isProxyUser$ = this.claims$
            .pipe(
                map(claims => claims.role?.indexOf('ProxyUser') > -1),
                shareReplay(1)
            );

        this.isDataKioskUser$ = this.claims$
            .pipe(
                map(claims => claims.role?.indexOf('DataKioskUser') > -1),
                shareReplay(1)
            );

        this.isCustomerUser$ = this.currentUserType$
            .pipe(
                map((userType) => userType === UserType.user || userType === UserType.customerService),
                startWith(false),
                shareReplay(1)
            );

        this.isCustomerAdmin$ = this.currentUserType$
            .pipe(
                map((userType) => userType === UserType.admin || userType === UserType.supervisor),
                startWith(false),
                shareReplay(1)
            );

        this.isStructureUser$ = this.currentUserType$
            .pipe(
                map((userType) => userType === UserType.customerService || userType === UserType.supervisor),
                startWith(false),
                shareReplay(1)
            );

        this.isStructureAdmin$ = this.currentUserType$
            .pipe(
                map((userType) => userType === UserType.supervisor),
                startWith(false),
                shareReplay(1)
            );

        this.isServiceUser$ = this.claims$
            .pipe(
                map(claims => claims.role?.indexOf("ServiceUser") > -1),
                shareReplay(1)
            );

        this.isLicenseAdmin$ = this.claims$
            .pipe(
                map(claims => claims.role?.indexOf('LicenseAdmin') > -1),
                shareReplay(1)
            );
    }

    login(): void {
        if(authConfig.showDebugInformation) {
            console.debug = console.log;
        }
        this.authService.configure(authConfig);
        this.authService.loadDiscoveryDocumentAndLogin().then(_ => {
            this.router.initialNavigation();
            if(this.authService.hasValidAccessToken()) {
                this.authService.loadUserProfile();
            }
            this.authService.setupAutomaticSilentRefresh();
        });
        this.authService.events.subscribe(event => {
            if(event.type == 'session_terminated') {
                this.authService.revokeTokenAndLogout().then(_=> window.location.reload());
            }
            if(event.type == 'token_received' && this.authService.hasValidAccessToken()) {
                this.authService.loadUserProfile();
            }
        });
    }

    revokeTokenAndLogout(): Promise<any> {
        return this.authService.revokeTokenAndLogout();
    }
}

export interface Claims {
    email: string;
    role: string[];
}
