import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RoleSelectModule} from "../shared/components/role-select/role-select.module";
import {FullComponent} from "./full/full.component";
import {VerticalHeaderComponent} from "./full/vertical-header/vertical-header.component";
import {VerticalSidebarComponent} from "./full/vertical-sidebar/vertical-sidebar.component";
import {BreadcrumbComponent} from "./full/breadcrumb/breadcrumb.component";
import {MaterialModule} from "../material-module";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {FlexLayoutModule} from "@angular/flex-layout";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {AccordionModule} from '../shared/components/accordion/accordion.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PerfectScrollbarModule,
        RoleSelectModule,
        TranslateModule,
        RouterModule,
        FlexLayoutModule,
        AccordionModule
    ],
    declarations: [
        FullComponent,
        VerticalHeaderComponent,
        VerticalSidebarComponent,
        BreadcrumbComponent,
        UnauthorizedComponent
    ],
    exports: [
        FullComponent
    ]
})
export class LayoutModule {
}
