import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {FullComponent} from "./layouts/full/full.component";
import {UnauthorizedComponent} from './layouts/unauthorized/unauthorized.component';

export const routes: Routes = [
    {
        path: '',
        component: FullComponent,
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    }
];

const config: ExtraOptions = {
    useHash: false,
    enableTracing: false,
    initialNavigation: 'disabled',
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
