import { Observable } from 'rxjs';
import { BaseData } from './base';

export interface ErrorStateConfig {
    id: number;
    deviceId: number;
    nicosStructureId: string;
    customerId: string;
    relevantErrorTypes: number;
    active: boolean;
}

export abstract class ErrorStateConfigData extends BaseData<ErrorStateConfig, number> {
    abstract getConfig(measuringPointId: string): Observable<ErrorStateConfig>;
    abstract updateConfig(config: ErrorStateConfig): Observable<boolean>;
}
