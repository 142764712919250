import { Observable } from 'rxjs';
import {DeviceType} from './devices';
import {BaseData} from './base';

export interface Template {
  id: string;
  name: string;
  deviceType: DeviceType;
}



export abstract class TemplateData extends BaseData<Template, number> {
  abstract getTemplates(projectId: string): Observable<Template[]>;
}

