import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {AuthService} from "../../../shared/auth/auth.service";
import {Observable} from "rxjs";
import {CustomerType} from "../../../shared/backend/device/model/customers";
import {map} from "rxjs/operators";

@Component({
    selector: 'nivus-vertical-sidebar',
    templateUrl: './vertical-sidebar.component.html',
    styleUrls: []
})

export class VerticalSidebarComponent implements OnDestroy, OnInit {
    mobileQuery: MediaQueryList;
    canHaveSubCustomers$: Observable<boolean>;

    private _mobileQueryListener: () => void;

    scrollToTop() {
        document.querySelector('.page-wrapper')?.scroll({
            top: 0,
            left: 0
        });
    }

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public authService: AuthService
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnInit() {
        this.canHaveSubCustomers$ = this.authService.currentUserRole$.pipe(
            map(role => role.customerType === CustomerType.distributor || role.customerType === CustomerType.reseller)
        );
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }
}
