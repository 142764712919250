import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {DeviceHttpService} from '../device-http.service';
import {TranslationsData} from '../model/translations';

@Injectable()
export class TranslationsService extends BaseService implements TranslationsData {
    constructor(http: DeviceHttpService) {
        super(http);
    }

    get apiEndpoint(): string {
        return 'translation';
    }

    uploadFile(file: File, deviceType: string): Observable<boolean> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("deviceType", deviceType);
        return this.http.post(`${this.apiEndpoint}`, formData);
    }
}
