import {DeviceReplace, NicosStructureCreate, NicosStructureInfo} from './../model/nicosStructure';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {NicosStructure, NicosStructureData} from '../model/nicosStructure';
import {DeviceHttpService} from '../device-http.service';
import {BaseService} from './base.service';

@Injectable()
export class NicosStructureService extends BaseService implements NicosStructureData {
    get apiEndpoint(): string {
        return 'nicosstructure';
    }

    public selectedMeasuringPoint$: ReplaySubject<NicosStructure|null>

    constructor(http: DeviceHttpService) {
        super(http);
        this.selectedMeasuringPoint$ = new ReplaySubject(1);
    }

    getProjects(customerId?: string): Observable<NicosStructureInfo[]> {
        const query = customerId != null ? `?customerId=${customerId}` : '';
        return this.http.get(`${this.apiEndpoint}/project${query}`);
    }

    getNicosStructure(id: string): Observable<NicosStructure> {
        return this.http.get(`${this.apiEndpoint}/${id}`);
    }

    createNicosStructure(nicosStructure: NicosStructureCreate): Observable<NicosStructure> {
        return this.http.post(`${this.apiEndpoint}`, nicosStructure);
    }

    updateNicosStructure(nicosStructure: NicosStructure): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${nicosStructure.id}`, nicosStructure);
    }

    removeNicosStructure(id: string): Observable<any> {
        return this.http.delete(`${this.apiEndpoint}/${id}`);
    }

    removeDevice(id: string, serial: string): Observable<any> {
        return this.http.delete(`${this.apiEndpoint}/${id}/device/${serial}`);
    }

    addDeviceToNicosStructure(id: string, serial: string): Observable<any> {
        const formData = new FormData();
        formData.set('serial', serial);
        return this.http.put(`${this.apiEndpoint}/${id}/device`, formData);
    }

    getUserByName(projectId: string, userName: string): Observable<NicosStructure>{
        return this.http.get(`${this.apiEndpoint}/${projectId}/users/${userName}`);
    }

    updateActiveState(id: string, active: boolean): Observable<boolean>{
        const formData = new FormData();
        formData.set('active', active ? 'true' : 'false');
        return this.http.put(`${this.apiEndpoint}/${id}/state`, formData);
    }
}
