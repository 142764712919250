import {Injectable, OnInit} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';
import {combineLatest} from "rxjs";

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate() {
        return combineLatest([
            this.authService.isCustomerAdmin$,
            this.authService.isDevicesUser$
        ]).pipe(
            map(([customerAdmin, devicesUser]) => {
               if(devicesUser && customerAdmin) {
                   return true;
               }
               if(!devicesUser)
                   this.router.navigate(['/unauthorized']);
               return false;
            })
        )
    }
}
