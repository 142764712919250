import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class CustomerHeaderInterceptor implements HttpInterceptor {
  private customer: string;
  constructor(private authService: AuthService) {
    this.authService.currentUserRole$.subscribe(role => {
      this.customer = role.customerId;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.customer != null) {
      const modifiedReq = req.clone({
        headers: req.headers.set('Customer', this.customer),
      });
      return next.handle(modifiedReq);
    }
    return next.handle(req);
  }
}
