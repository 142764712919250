import {Base} from '../../common';
import {Observable} from 'rxjs';
import {BaseData} from './base';
import {CustomerState, CustomerType} from './customers';

export interface User extends Base<number> {
    email: string;
    roles: UserRole[];
}

export interface UserInfo {
    id: number;
    name: string;
}

export interface UserForm {
    id: number;
    name: string;
    email: string;
    type: UserType;
}

export class UserRole {
    role: UserType;
    customerId: string;
    customerName: string;
    customerType: CustomerType;
    customerState: CustomerState;
    inherited: boolean;
}

export enum UserType {
    guest = 'Guest',
    user = 'User',
    admin = 'Admin',
    customerService = 'CustomerService',
    supervisor = 'Supervisor',
}

export abstract class UsersData extends BaseData<User, number> {
    abstract getCurrent(): Observable<User>;
}

