<mat-nav-list>
    <ng-container *ngIf="authService.isDevicesUser$ | async">
        <mat-list-item routerLinkActive="selected" group="dashboard">
            <a [routerLink]="['/', 'dashboard']">
                <mat-icon>apps</mat-icon>
                <span fxFlex>{{ 'DASHBOARD' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item>
            <hr />
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="projects">
            <a [routerLink]="['/', 'projects']">
                <mat-icon>analytics</mat-icon>
                <span fxFlex>{{ 'PROJECTS' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="devices">
            <a [routerLink]="['/', 'devices']">
                <mat-icon>router</mat-icon>
                <span fxFlex>{{ 'DEVICES' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="licenses">
            <a [routerLink]="['/', 'licenses']">
                <mat-icon>verified</mat-icon>
                <span fxFlex>{{ 'LICENSES' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="workspaces">
            <a [routerLink]="['/', 'workspaces']">
                <mat-icon>workspaces</mat-icon>
                <span fxFlex>{{ 'WORKSPACES' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="rentals" *ngIf="(authService.isServiceUser$ | async)">
            <a [routerLink]="['/', 'rentals']">
                <mat-icon>history</mat-icon>
                <span fxFlex>{{ 'RENTALS' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item>
            <hr />
        </mat-list-item>
    </ng-container>
    <mat-list-item routerLinkActive="selected" group="files">
        <a [routerLink]="['/', 'files']">
            <mat-icon>folder_shared</mat-icon>
            <span fxFlex>{{ 'FILES' | translate }}</span>
        </a>
    </mat-list-item>
    <ng-container *ngIf="authService.isDevicesUser$ | async">
        <mat-list-item *ngIf="(authService.isCustomerAdmin$ | async) || (authService.isStructureAdmin$ | async)">
            <hr />
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="customers" *ngIf="(authService.isStructureAdmin$ | async) && (canHaveSubCustomers$ | async)">
            <a [routerLink]="['/', 'admin', 'customers']">
                <mat-icon>business</mat-icon>
                <span fxFlex>{{ 'CUSTOMERS' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="users" *ngIf="authService.isCustomerAdmin$ | async">
            <a [routerLink]="['/', 'admin', 'users']">
                <mat-icon>people</mat-icon>
                <span fxFlex>{{ 'USERS' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="errorlog" *ngIf="(authService.isCustomerAdmin$ | async)">
            <a [routerLink]="['/', 'admin', 'errorlog']">
                <mat-icon>description</mat-icon>
                <span fxFlex>{{ 'ERROR_LOG' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="statistics" *ngIf="(authService.isCustomerAdmin$ | async)">
            <a [routerLink]="['/', 'admin', 'statistics']">
                <mat-icon>insert_chart</mat-icon>
                <span fxFlex>{{ 'STATISTICS' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item *ngIf="(authService.isMqttAdmin$ | async) || (authService.isSimAdmin$ | async) || (authService.isServiceUser$)">
            <hr />
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="mqtt" *ngIf="(authService.isMqttAdmin$ | async)">
            <a [routerLink]="['/', 'admin', 'mqtt']">
                <mat-icon>cloud</mat-icon>
                <span fxFlex>{{ 'MQTT' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="sim" *ngIf="(authService.isServiceUser$ | async)">
            <a [routerLink]="['/', 'admin', 'erp']">
                <mat-icon>table_view</mat-icon>
                <span fxFlex>{{ 'PROCESSES' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="sim" *ngIf="(authService.isSimAdmin$ | async)">
            <a [routerLink]="['/', 'admin', 'sim']">
                <mat-icon>sim_card</mat-icon>
                <span fxFlex>{{ 'SIM_CARDS' | translate }}</span>
            </a>
        </mat-list-item>
        <mat-list-item routerLinkActive="selected" group="system" *ngIf="(authService.isServiceUser$ | async)">
            <a [routerLink]="['/', 'admin', 'system']">
                <mat-icon>settings_applications</mat-icon>
                <span fxFlex>{{ 'SYSTEM' | translate }}</span>
            </a>
        </mat-list-item>
    </ng-container>
</mat-nav-list>
