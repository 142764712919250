import {Base} from '../../common';
import {Customer} from './customers';
import {User} from './users';
import {BaseData} from './base';
import {Observable} from 'rxjs';


export interface CustomerFile extends Base<string> {
    size: number;
    uploadDate: string;
    expirationDate: string;
    customer?: Customer;
    customerId: string;
    user: User;
}

export abstract class FileData extends BaseData<CustomerFile, string> {
    abstract getFiles(): Observable<CustomerFile[]>;
    abstract uploadFile(file: File, users: string[], customerId: string):Observable<CustomerFile>;
    abstract downloadFile(id: string):Observable<Blob>;
    abstract deleteFile(id:string): Observable<boolean>;
    abstract updateFile(file:CustomerFile, id: string):Observable<boolean>;
    abstract getQuota(customerId: string):Observable<number>;
}

