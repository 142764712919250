import {Injectable} from '@angular/core';
import {DeviceData, Device, MqttMessage, DeviceMessages, DeviceLog, DeviceInfo, DeviceCreate, DeviceConfig} from '../model/devices';
import {Observable, ReplaySubject} from 'rxjs';
import {DeviceHttpService} from '../device-http.service';
import {BaseService} from './base.service';
import {DeviceReplace} from '../model/nicosStructure';
import {ConfigurationUpdate} from '../../../../pages/devices/device-configuration/device-configuration.component';

@Injectable()
export class DevicesService extends BaseService implements DeviceData {
    get apiEndpoint(): string {
        return 'device';
    }

    public selectedDevice$: ReplaySubject<Device|null>;

    constructor(http: DeviceHttpService) {
        super(http);
        this.selectedDevice$ = new ReplaySubject(1);
    }

    getDevices(): Observable<DeviceInfo[]> {
        return this.http.get(this.apiEndpoint);
    }

    getWithDetails(id: number): Observable<Device> {
        return this.http.get(`${this.apiEndpoint}/${id}/details`);
    }

    getByCustomerId(customerId: string): Observable<DeviceInfo[]> {
        return this.http.get(`${this.apiEndpoint}?customerId=${customerId}`);
    }

    linkDeviceToCustomer(deviceId: number, customerId: string, additionalInfo: string): Observable<boolean> {
        const formData = new FormData();
        formData.set('additionalInfo', additionalInfo);
        return this.http.put(`${this.apiEndpoint}/${deviceId}/link/${customerId}`, formData);
    }

    rentDeviceToCustomer(deviceId: number, customerId: string, additionalInfo: string): Observable<boolean> {
        const formData = new FormData();
        formData.set('additionalInfo', additionalInfo);
        return this.http.put(`${this.apiEndpoint}/${deviceId}/rent/${customerId}`, formData);
    }

    returnDeviceToOwner(deviceId: number): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${deviceId}/return/`);
    }

    getMessages(serials: string, from: string, to: string, topic: string): Observable<DeviceMessages[]> {
        return this.http.get(`${this.apiEndpoint}/mqtt?serials=${serials}&from=${from}&to=${to}&topic=${topic}`);
    }

    republishMessages(serials: string, from: string, to: string, topic: string): Observable<DeviceMessages[]> {
        return this.http.post(`${this.apiEndpoint}/mqtt/republish`, {serial: serials, from, to, topic});
    }

    getLogs(deviceId: number, amount: number, offset: number): Observable<DeviceLog[]> {
        return this.http.get(`${this.apiEndpoint}/${deviceId}/log?amount=${amount}&offset=${offset}`);
    }

    removeSimcard(serial: string): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/serial/${serial}/sim`);
    }

    changeSimcard(serial: string, iccid: string): Observable<boolean> {
        const formData = new FormData();
        formData.set('iccid', iccid);
        return this.http.post(`${this.apiEndpoint}/serial/${serial}/sim`, formData);
    }

    updateDevice(deviceId: number, device: Device): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${deviceId}`, device);
    }

    replaceDevice(deviceReplace:DeviceReplace): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/replace`, deviceReplace);
    }

    createDevice(deviceCreate:DeviceCreate): Observable<Device> {
        return this.http.post(`${this.apiEndpoint}`, deviceCreate);
    }

    updateConfiguration(deviceId: number, config: ConfigurationUpdate): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${deviceId}/configuration`, config);
    }

    getCurrentConfigurations(deviceId: number): Observable<DeviceConfig[]> {
        return this.http.get(`${this.apiEndpoint}/${deviceId}/configuration/current`);
    }

    getBySerial(serial: string): Observable<Device> {
        return this.http.get(`${this.apiEndpoint}/serial/${serial}`);
    }

    scrapDevice(id: number): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${id}`);
    }
}
