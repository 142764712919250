import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DeviceHttpService extends HttpService {
    get apiUrl(): string {
        return environment.apiUrl;
    }

    constructor(client: HttpClient) {
        super(client);
    }
}
