import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';

@Injectable()
export class FileGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate() {
        return this.authService.isLoggedIn$.pipe(
            map(loggedIn => {
               if(loggedIn) {
                   return true;
               }
               this.router.navigate(['/unauthorized']);
               return false;
            })
        )
    }
}
