import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Rental, RentalCreate, RentalData} from '../model/rentals';
import {Observable} from 'rxjs';
import {DeviceHttpService} from '../device-http.service';
import {DeviceInfo} from '../model/devices';
import {UserInfo} from '../model/users';

@Injectable()
export class RentalsService extends BaseService implements RentalData {
    get apiEndpoint(): string {
        return 'rental';
    }

    constructor(http: DeviceHttpService) {
        super(http);
    }

    getRentals(from: string | null, to:string | null): Observable<Rental[]> {
        let url = this.apiEndpoint;
        if (from && to) {
            url = `${url}?from=${from}&to=${to}`;
        }
        else if(from) {
            url = `${url}?from=${from}`;
        }
        else if (to) {
            url = `${url}&to=${to}`;
        }

        return this.http.get(url);
    }

    getRental(id: number): Observable<Rental> {
        return this.http.get(`${this.apiEndpoint}/${id}`);
    }

    createRental(create: RentalCreate): Observable<Rental> {
        return this.http.post(`${this.apiEndpoint}`, create);
    }

    updateRental(rental: Rental): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${rental.id}`, rental);
    }

    deleteRental(id: number): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${id}`);
    }

    getByDeviceid(deviceId: number): Observable<Rental[]> {
        return this.http.get(`${this.apiEndpoint}/device/${deviceId}`);
    }

    addDevices(id: number, requirementId: number): Observable<boolean> {
        const formData = new FormData();
        formData.append('requirementId', requirementId.toString());
        return this.http.put(`${this.apiEndpoint}/${id}/devices`, formData);
    }

    getDevicesAvailable(id: number, requirementId: number): Observable<DeviceInfo[]> {
        return this.http.get(`${this.apiEndpoint}/${id}/devices?requirementId=${requirementId}`);
    }

    addDevicesToPool(deviceIds: number[]): Observable<boolean> {
        const formData = new FormData();
        for(let i = 0; i < deviceIds.length; i++) {
            formData.append("deviceIds", deviceIds[i].toString());
        }
        return this.http.put(`${this.apiEndpoint}/devices`, formData);
    }

    removeDeviceFromPool(deviceId: number): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/devices/${deviceId}`);
    }

    getUsersAvailable(id: number): Observable<UserInfo[]> {
        return this.http.get(`${this.apiEndpoint}/${id}/users`);
    }

    startRental(id: number, createProject: boolean, projectName: string): Observable<boolean> {
        const formData = new FormData();
        formData.set('projectName', projectName);
        formData.set('createProject', createProject.toString());
        return this.http.put(`${this.apiEndpoint}/${id}/start`, formData);
    }

    stopRental(id: number, additionalInfo: boolean): Observable<boolean> {
        const formData = new FormData();
        formData.set('additionalInfo', additionalInfo.toString());
        return this.http.put(`${this.apiEndpoint}/${id}/stop`, formData);
    }

}
