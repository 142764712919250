import {NgModule} from '@angular/core';
import {AccordionAnchorDirective} from './accordionanchor.directive';
import {AccordionDirective} from './accordion.directive';
import {AccordionLinkDirective} from './accordionlink.directive';

@NgModule({
    declarations: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective
    ],
    exports: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective
    ],
})
export class AccordionModule { }
