import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Template, TemplateData} from '../model/template';
import {DeviceHttpService} from '../device-http.service';
import {BaseService} from './base.service';

@Injectable()
export class TemplateService extends BaseService implements TemplateData {
    get apiEndpoint(): string {
        return 'template';
    }

    constructor(http: DeviceHttpService) {
        super(http);
    }

    getTemplates(projectId: string): Observable<Template[]> {
        return this.http.get(`${this.apiEndpoint}/${projectId}`);
    }
}
