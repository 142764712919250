import {NicosStructure} from './nicosStructure';
import {Observable} from 'rxjs';
import {Customer} from './customers';
import {Device, DeviceType} from './devices';
import {BaseData} from './base';

export interface License {
    id: number;
    serial: string;
    article: string;
    owner: Customer;
    licenseKey: string;
    licenseToken: string;
    licenseKeyValidDate: string;
    currentCustomer: Customer;
    device: Device;
    nicosStructure: NicosStructure;
    erpReferenceId: string;
}

export abstract class LicenseData extends BaseData<License, number> {
    abstract getLicenses(): Observable<License[]>;

    abstract getById(id: number): Observable<License>;

    abstract getByCustomerId(customerId: string): Observable<License[]>;

    abstract getUnAssignedProjectLicenses(customerId: string): Observable<License[]>;

    abstract getUnAssignedMeasuringPointLicenses(customerId: string, articles: string[] | null): Observable<License[]>;

    abstract getUnAssignedDeviceLicenses(customerId: string, articles: string[] | null, deviceType: DeviceType): Observable<License[]>;

    abstract linkLicenseToCustomer(licenseId: number, customerId: string, additionalInfo: string): Observable<boolean>;

    abstract rentLicenseToCustomer(licenseId: number, customerId: string, additionalInfo: string): Observable<boolean>;

    abstract returnLicenseToOwner(licenseId: number): Observable<boolean>;

    abstract getByDeviceId(id: number): Observable<License[]>;

    abstract getByNicosStructureId(id: string): Observable<License[]>;

    abstract unAssignLicense(token: string, deactivateSim: boolean): Observable<boolean>;

    abstract assignLicense(token: string, identifier: string): Observable<boolean>;

    abstract getLicenseTypeByArticle(article: string): LicenseType;
    abstract isConnectLicense(article: string): boolean;
}

/* eslint @typescript-eslint/no-shadow: "off" */
export enum LicenseType {
    MeasuringPoint = 0,
    Device = 1,
    Project = 2
}

export enum LicenseArticle {
    DataKioskRemoteAccess = 'SNI0DKSREMOTEAC',
    ProjectLicense = 'SNI0WEBMESSPROL',
    MeasuringPointLicense = 'SNI0WEBMESSSTEL',
    WebportalLicense = 'SNI0WEBPORTAL',
    CustomerSms = 'SNI0ALARMSMS01',
    WebportalSms = 'SNI0WEBPSMS',
    Connect01 = 'SNI0CONNECT01',
    Connect02 = 'SNI0CONNECT02',
    Connect03 = 'SNI0CONNECT03',
    WebGraph = 'SNI0WEBPGRAPH',
    WebVisu = 'SNI0WEBPVISU',
    WebMail = 'SNI0WEBPMAIL',
    WebBeri = 'SNI0WEBPBERI',
    DataMs = 'SNI0DKSDATAMS',
    DataKioskLicense = 'SNI0HUBMESSSTEL',
    NfmErw = 'NFM0LIZENZERW',
    NfmExp = 'NFM0LIZENZEXP',
    NfmFtp = 'NFM0LIZENZFTP',
    NfsGeo = 'NFS0LIZENZGEO',
    NfErw = 'NFX0LIZENZERW',
    NfExp = 'NFX0LIZENZEXP',
    NfFtp = 'NFX0LIZENZFTP',
    NfHart = 'NFX0LIZENZHART',
    NfModb = 'NFX0LIZENZMODB',
    NfPwdn = 'NFX0LIZENZPWDN',
    NfRegl = 'NFX0LIZENZREGL',
    NfSoff = 'NFX0LIZENZSOFF',
}
