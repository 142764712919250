import {Component, OnInit} from '@angular/core';
import {UserRole, UsersData, UserType} from '../../backend/device/model/users';
import {CurrentUserService} from './current-user.service';
import {Observable, tap} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {getCookie, setCookie} from 'typescript-cookie';
import {CustomerState} from '../../backend/device/model/customers';

@Component({
    selector: 'nivus-role-select',
    styleUrls: ['./role-select.component.scss'],
    templateUrl: './role-select.component.html',
})
export class RoleSelectComponent implements OnInit {
    filterValue: string;

    roles$: Observable<UserRole[]>;
    selectedRole$: Observable<UserRole>;

    constructor(private userData: UsersData, private currentUser: CurrentUserService) {
    }

    ngOnInit(): void {
        this.roles$ = this.currentUser.user
            .pipe(
                map(user => user?.roles?.filter(c => c.customerState === CustomerState.active) ?? []),
                tap(roles => roles.sort(this.byDepthAndName)),
                tap(roles => this.initialSelect(roles)),
                shareReplay({bufferSize: 1, refCount: true})
            );
        this.selectedRole$ = this.currentUser.selectedRole;
        this.currentUser.refresh();
    }

    byDepthAndName(a: UserRole, b: UserRole) {
        const aCustomerDepth = a.customerId.split('_').length;
        const bCustomerDepth = b.customerId.split('_').length;

        if (aCustomerDepth === bCustomerDepth) {
            return a?.customerName ? a.customerName.localeCompare(b?.customerName) : -1;
        } else {
            return aCustomerDepth < bCustomerDepth ? -1 : 1;
        }
    }

    initialSelect(roles: UserRole[]) {
        if (roles?.length > 0) {
            const currentCustomerId = getCookie('CurrentCustomer');
            const customer = getCookie('Customer')?.split('|');
            const role = roles.find(r =>
                !customer
                || customer.length < 2
                || currentCustomerId  ? r.customerId === currentCustomerId :
                    r.customerId === customer[0] && r.role === customer[1]);
            this.selectRole(role || roles[0]);
            if(currentCustomerId) {
                setCookie('CurrentCustomer', '');
            }
        }
    }

    selectRole(role: UserRole) {
        this.filterValue = '';
        this.currentUser.selectedRole.next(role);
        setCookie('Customer', `${role.customerId}|${role.role}`);
    }

    stopPropagation($event: any) {
        $event.stopPropagation();
    }


    getColor(userType: UserType): string {
        switch (userType) {
            case UserType.user:
            case UserType.customerService:
                return 'primary';
            case UserType.admin:
            case UserType.supervisor:
                return 'accent';
            default:
                return 'info';
        }
    }
}
