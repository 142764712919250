import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {User, UserRole, UsersData} from '../../backend/device/model/users';

@Injectable({
    providedIn: 'root',
})
export class CurrentUserService {
    selectedRole = new ReplaySubject<UserRole>(1);
    user = new ReplaySubject<User>(1);

    constructor(private userData: UsersData) {
    }

    refresh() {
        this.userData.getCurrent().subscribe(user => this.user.next(user));
    }
}
