import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DeviceHttpService} from '../device-http.service';
import {BaseService} from './base.service';
import {ErrorStateConfig, ErrorStateConfigData} from '../model/error-state-config';

@Injectable()
export class ErrorStateConfigService extends BaseService implements ErrorStateConfigData {

    get apiEndpoint(): string {
        return 'errorstate';
    }

    constructor(http: DeviceHttpService) {
        super(http);
    }

    getConfig(measuringPointId: string): Observable<ErrorStateConfig> {
        return this.http.get(`${this.apiEndpoint}/config?measuringPointId=${measuringPointId}`);
    }

    updateConfig(config: ErrorStateConfig): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/config`, config);
    }
}
