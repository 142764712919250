import {Base} from '../../common';
import {CustomerInfo} from './customers';
import {BaseData} from './base';
import {Observable} from 'rxjs';

export interface ErpProcess extends Base<number> {
    erpReferenceId: string;
    customerId: string;
    customer: CustomerInfo;
    content: string;
    email: string;
    acknowledged: boolean;
}

export abstract class ErpData extends BaseData<ErpProcess, number> {
    abstract getProcesses(): Observable<ErpProcess[]>;
    abstract updateProcess(process: ErpProcess): Observable<boolean>;
}
