import {Injectable} from '@angular/core';
import {combineLatest, Observable, ReplaySubject, timer} from 'rxjs';
import {ErrorState, ErrorStateData, ErrorStateType} from '../model/error-state';
import {DeviceHttpService} from '../device-http.service';
import {BaseService} from './base.service';
import {shareReplay, switchMap} from "rxjs/operators";

@Injectable()
export class ErrorStateService extends BaseService implements ErrorStateData {
    all$: Observable<ErrorState[]>;
    reload$ = new ReplaySubject<boolean>(1);
    private refreshInterval = 15*60*1000;

    get apiEndpoint(): string {
        return 'errorstate';
    }

    constructor(http: DeviceHttpService) {
        super(http);
    }

    getAll(): Observable<ErrorState[]> {
        return this.http.get(`${this.apiEndpoint}`)
    }

    getAllCached(): Observable<ErrorState[]> {
        if(!this.all$) {
            this.all$ = combineLatest([this.reload$, timer(0, this.refreshInterval)])
                .pipe(
                    switchMap(_=> this.getAll()),
                    shareReplay(1)
                );
        }
        this.reload$.next(true);
        return this.all$;
    }

    reload(): void {
        this.reload$.next(true);
    }

    getFiltered(projectId: string): Observable<ErrorState[]> {
        return this.http.get(`${this.apiEndpoint}?projectId=${projectId}`);
    }

    recalculateErrorStates(): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/recalculate`, null);
    }

    recalculateErrorStatesByProjectId(projectId: string): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/recalculate/${projectId}`, null);
    }

    getBgClass(state: ErrorStateType): string {
        switch (state) {
            case ErrorStateType.ok:
                return 'bg-success';
            case ErrorStateType.warning:
                return 'bg-info';
            case ErrorStateType.error:
                return 'bg-warning';
            case ErrorStateType.warning + ErrorStateType.error:
                return 'bg-danger';
            default:
                return '';
        }
    }

    getIcon(state: ErrorStateType): string {
        switch (state) {
            case ErrorStateType.ok:
                return 'check';
            case ErrorStateType.warning:
                return 'warning';
            case ErrorStateType.error:
                return 'error';
            case ErrorStateType.warning + ErrorStateType.error:
                return 'error';
            default:
                return 'question_mark';
        }
    }
}
