import {HttpClient} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export function translateLoader(http: HttpClient) {

    return new MultiTranslateHttpLoader(http, [
        {prefix: './assets/i18n/', suffix: '.json'},
        {prefix: './assets/i18n/countries-', suffix: '.json'}
    ]);
}

export class MultiTranslateHttpLoader implements TranslateLoader {

    constructor(private http: HttpClient,
                public resources: { prefix: string, suffix: string }[] = [{
                    prefix: '/assets/i18n/',
                    suffix: '.json'
                }]) {}

    /**
     * Gets the translations from the server
     * @param lang
     * @returns {any}
     */
    public getTranslation(lang: string): Observable<any> {

        let languageObservables = forkJoin(this.resources.map(config => {
            return this.http.get(`${config.prefix}${lang}${config.suffix}`);
        }));
        return languageObservables.pipe(map(response => {
            return response.reduce((a, b) => {
                return Object.assign(a, b);
            });
        }));
    }
}
