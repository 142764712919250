import {Customer, CustomerInfo} from 'src/app/shared/backend/device/model/customers';
import {Observable} from 'rxjs/internal/Observable';
import {Base, BaseInfo} from '../../common';
import {DeviceInfo} from "./devices";
import {BaseData} from './base';
import {ReplaySubject} from 'rxjs';


export interface NicosStructure extends Base<string> {
    id: string;
    type: NicosStructureType;
    name: string;
    customer: CustomerInfo;
    parentId: string;
    children: NicosStructureInfo[];
    device?: DeviceInfo;
    active: boolean;
    hasDevices: boolean;
    longitude?: number;
    latitude?: number;
    notes: string;
}

export interface NicosStructureInfo extends BaseInfo<string> {
    type: NicosStructureType;
    parentId: string;
    device?: DeviceInfo;
    customer: CustomerInfo;
    active: boolean;
}

export enum NicosStructureType {
    rts = 'Rts',
    project = 'Project',
    measuringPoint = 'MeasuringPoint',
    user = 'User'
}

export abstract class NicosStructureData extends BaseData<NicosStructure, string> {
    public selectedMeasuringPoint$: ReplaySubject<NicosStructure|null>;
    abstract getProjects(customerId?: string): Observable<NicosStructureInfo[]>;

    abstract getNicosStructure(id: string): Observable<NicosStructure>;

    abstract getUserByName(projectId: string, userName: string): Observable<NicosStructure>;

    abstract createNicosStructure(nicosStructure: NicosStructureCreate): Observable<NicosStructure>;

    abstract updateNicosStructure(nicosStructure: NicosStructure): Observable<boolean>;

    abstract removeNicosStructure(id: string): Observable<boolean>;

    abstract removeDevice(id: string, serial: string): Observable<boolean>;

    abstract addDeviceToNicosStructure(id: string, serial: string): Observable<boolean>;

    abstract updateActiveState(id:string, active:boolean) : Observable<boolean>;
}

export interface NicosStructureCreate {
    type: NicosStructureType;
    name: string;
    customer: CustomerInfo;
    parentId: string;
    additionalInfo?: string;
}

export interface DeviceReplace {
    oldSerial: string;
    newSerial: string;
    scrapOldDevice: boolean;
    newCustomer?: Customer;
    additionalInfo: string;
    transferLicenses: boolean
}




