import {Base, BaseInfo} from '../../common';
import {Observable} from 'rxjs';
import {User, UserForm} from './users';
import {BaseData} from './base';

export interface Customer extends Base<string> {
    parentId: string;
    referenceId: string;
    name: string;
    type: CustomerType;
    customers: Customer[];
    state: CustomerState
}

export interface CustomerInfo extends BaseInfo<string> {
    parentId: string;
    referenceId: string;
    country?: string;
    lang?: string;
}

export interface CustomerRow {
    id: string,
    name: string;
}

export enum CustomerType {
    customer = 'Customer',
    distributor = 'Distributor',
    reseller = 'Reseller',
    workspace = 'Workspace'
}

export enum CustomerState {
    active = 'Active',
    deleted = 'Deleted'
}

export interface WorkspaceCreate {
    name: string;
    country: string;
}

export abstract class CustomersData extends BaseData<Customer, string> {
    abstract getCustomer(id: string): Observable<Customer>;

    abstract getCustomers(): Observable<Customer[]>;

    abstract addCustomer(parentId: string, customer: Customer): Observable<Customer>;

    abstract addWorkspace(model: WorkspaceCreate): Observable<Customer>;

    abstract updateCustomer(customer: Customer): Observable<boolean>;

    abstract getUsers(customerId: string): Observable<User[]>;

    abstract addUser(customerId: string, user: UserForm): Observable<User>;

    abstract updateUser(customerId: string, user: UserForm): Observable<boolean>;

    abstract removeUser(customerId: string, userId: number): Observable<boolean>;

    abstract deleteWorkspace(id: string) : Observable<boolean>;
}

