import { Component } from '@angular/core';
import { AuthService } from './shared/auth/auth.service';

@Component({
  selector: 'nivus-devices-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {

  constructor(private authService: AuthService) {
    this.authService.login();
  }
}
