import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Template, TemplateData} from '../model/template';
import {DeviceHttpService} from '../device-http.service';
import {BaseService} from './base.service';
import {Statistics, StatisticsData} from '../model/statistics';

@Injectable()
export class StatisticsService extends BaseService implements StatisticsData {
    get apiEndpoint(): string {
        return 'statistics';
    }

    constructor(http: DeviceHttpService) {
        super(http);
    }

    getStatistics(from: string, to: string): Observable<Statistics> {
        return this.http.get(`${this.apiEndpoint}?from=${from}&to=${to}`);
    }
}
