import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UsersData, User} from '../model/users';
import {DeviceHttpService} from '../device-http.service';
import {BaseService} from './base.service';

@Injectable()
export class UsersService extends BaseService implements UsersData {
    get apiEndpoint(): string {
        return 'user';
    }

    constructor(http: DeviceHttpService) {
        super(http);
    }

    getCurrent(): Observable<User> {
        return this.http.get(`${this.apiEndpoint}/current`);
    }
}
