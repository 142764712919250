import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {OAuthModule} from 'angular-oauth2-oidc';
import {AuthGuard} from './shared/auth/auth.guard';
import {DeviceBackendModule} from './shared/backend/device/device-backend.module';
import {environment} from 'src/environments/environment';
import {CustomerHeaderInterceptor} from './shared/auth/customer-header.interceptor';
import {AuthService} from './shared/auth/auth.service';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {LayoutModule} from "./layouts/layout.module";
import {AdminGuard} from "./shared/auth/admin.guard";
import {MultiTranslateHttpLoader} from './MultiTranslateHttpLoader';
import {FileGuard} from "./shared/auth/file.guard";
import {NgApexchartsModule} from 'ng-apexcharts';
import {AccordionModule} from './shared/components/accordion/accordion.module';

export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        {prefix: './assets/i18n/', suffix: '.json?v=' + environment.version},
        {prefix: environment.apiUrl +  '/i18n/nfm_', suffix: '.json?v=' + environment.version},
        {prefix: environment.apiUrl +  '/i18n/nf_', suffix: '.json?v=' + environment.version},
        {prefix: environment.apiUrl +  '/i18n/nlg2_', suffix: '.json?v=' + environment.version}
    ]);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatMomentDateModule,
        NgApexchartsModule,
        AppRoutingModule,
        LayoutModule,
        AccordionModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.apiUrl],
                sendAccessToken: true
            }
        }),
        DeviceBackendModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        AuthGuard,
        FileGuard,
        AdminGuard,
        AuthService,
        [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: CustomerHeaderInterceptor,
                multi: true
            },
            {
                provide: PERFECT_SCROLLBAR_CONFIG,
                useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
            }
        ]
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
