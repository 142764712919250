import {DeviceReplace, NicosStructure} from './nicosStructure';
import {Observable, ReplaySubject} from 'rxjs';
import {Customer, CustomerInfo} from './customers';
import {SimCard} from './simcards';
import {BaseData} from './base';
import {BaseInfo} from "../../common";
import {ConfigurationUpdate} from '../../../../pages/devices/device-configuration/device-configuration.component';

export interface Device {
    id: number;
    serial: string;
    articleNumber: string;
    customer: Customer;
    currentCustomer: Customer;
    simCard: SimCard | null;
    project: NicosStructure | null;
    measuringPoint: NicosStructure | null;
    lastCommunication: string;
    communicationInterval: number;
    deviceType: DeviceType;
    assignedIp: string;
    state: DeviceState;
    username: string;
    password: string;
    erpReferenceId: string;
    rentalPool: boolean;
    notes: string;
    deleted: boolean;
}

export interface DeviceInfo extends BaseInfo<number> {
    deviceType: DeviceType;
    articleNumber: string;
    simId: string;
    erpReferenceId: string;
    customer: CustomerInfo;
    owner: CustomerInfo;
    projectId: string;
    measuringPointId: string;
    rentalPool: boolean;
    deleted: boolean;
}

export enum DeviceTypes {
    nfm = 'NivuFlow Mobile',
    nf = 'NivuFlow',
    nlc = 'NivuLink Control',
    nlg = 'NivuLink Micro',
    d2W = 'NivuLog',
    nlr = 'NivuLink Radar',
    nlw = 'NivuLink Wetter',
    poi = 'POI'
}

export enum DeviceType {
    Unknown = 'Unknown',
    NFM = 'NFM',
    NF = 'NF',
    NLC = 'NLC',
    NLG = 'NLG',
    D2W = 'D2W',
    NLG2 = 'NLG2',
    NFS = 'NFS',
    RMI = 'RMI',
    NLR = 'NLR',
    NLW = 'NLW',
    POI = 'POI'
}

export enum DeviceState {
    inactive = 'Inactive',
    active = 'Active',
    deleted = 'Deleted'
}


export interface DeviceLog {
    id: number;
    topic: string;
    deviceId: number;
    data: string;
    created: string;
}

export interface DeviceMessages {
    serial: string;
    messages: MqttMessage[];
}

export interface MqttMessage {
    timestamp: string;
    topic: string;
}

export interface DeviceCreate {
    serial: string;
    deviceType: DeviceType;
    communication: string;
    protocol: string;
    articleNumber: string;
    customer: Customer;
    simIccId?: string;
    erpReferenceId?: string;
}

export interface DeviceConfig {
    id: number;
    data: string;
    status: DeviceConfigStatus;
    modified: string;
}

export enum DeviceConfigStatus {
    pending = 'Pending',
    active = 'Active'
}

export abstract class DeviceData extends BaseData<Device, number> {
    public selectedDevice$: ReplaySubject<Device|null>;

    abstract getDevices(): Observable<DeviceInfo[]>;

    abstract getWithDetails(id: number): Observable<Device>;

    abstract getByCustomerId(customerId: string): Observable<DeviceInfo[]>;

    abstract linkDeviceToCustomer(deviceId: number, customerId: string, additionalInfo: string): Observable<boolean>;

    abstract rentDeviceToCustomer(deviceId: number, customerId: string, additionalInfo: string | null): Observable<boolean>;

    abstract returnDeviceToOwner(deviceId: number): Observable<boolean>;

    abstract getMessages(serials: string, from: string, to: string, topic: string): Observable<DeviceMessages[]>;

    abstract republishMessages(serials: string, from: string, to: string, topic: string): Observable<DeviceMessages[]>;

    abstract getLogs(deviceId: number, amount: number, offset: number): Observable<DeviceLog[]>;

    abstract removeSimcard(serial: string): Observable<boolean>;

    abstract changeSimcard(serial: string, iccid: string): Observable<boolean>;

    abstract updateDevice(deviceId: number, device: Device): Observable<boolean>;

    abstract replaceDevice(deviceReplace: DeviceReplace): Observable<boolean>;

    abstract createDevice(deviceCreate: DeviceCreate): Observable<Device>;

    abstract getCurrentConfigurations(deviceId: number):Observable<DeviceConfig[]>;

    abstract updateConfiguration(deviceId: number, config: ConfigurationUpdate) : Observable<boolean>;

    abstract getBySerial(serial: string): Observable<Device>;
    abstract scrapDevice(id: number): Observable<boolean>;
}
