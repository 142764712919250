import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {DeviceHttpService} from '../device-http.service';
import {ErpData, ErpProcess} from '../model/erp';

@Injectable()
export class ErpService extends BaseService implements ErpData {
    get apiEndpoint(): string {
        return 'erpData';
    }

    constructor(http: DeviceHttpService) {
        super(http);
    }

    getProcesses(): Observable<ErpProcess[]> {
        return this.http.get(this.apiEndpoint);
    }

    updateProcess(process: ErpProcess): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${process.id}`, process);
    }
}
