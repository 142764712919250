<div class="page-breadcrumb p-t-10 p-b-10 p-l-20 p-r-20">
    <div fxLayout="row">
        <div fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="100" class="align-self-center align-items-center d-md-none"
             fxLayout="row">
            <ng-container *ngFor="let title of pageInfo?.title">
                <h3 class="page-title m-0">{{title | translate}}</h3>
                <mat-icon>chevron_right</mat-icon>
            </ng-container>
            <nivus-role-select [class.hide]="(authService.isLoggedIn$ | async) === false || pageInfo.noRoles"></nivus-role-select>
        </div>
        <span fxFlex></span>
        <button mat-icon-button (click)="openHelp()" matTooltip="{{ 'ONLINE_HELP' | translate }}" *ngIf="(authService.isDevicesUser$ | async)">
            <mat-icon>help_outline</mat-icon>
        </button>
    </div>
</div>
