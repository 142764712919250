import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomersData, Customer, WorkspaceCreate} from '../model/customers';
import {UserForm, User} from '../model/users';
import {DeviceHttpService} from '../device-http.service';
import {BaseService} from './base.service';

@Injectable()
export class CustomersService extends BaseService implements CustomersData {
    get apiEndpoint(): string {
        return 'customer';
    }

    constructor(http: DeviceHttpService) {
        super(http);
    }

    getCustomer(id: string): Observable<Customer> {
        return this.http.get(`${this.apiEndpoint}/${id}`);
    }
    getCustomers(): Observable<Customer[]> {
        return this.http.get(this.apiEndpoint);
    }
    addCustomer(parentId: string, customer: Customer): Observable<Customer> {
        return this.http.post(`${this.apiEndpoint}/${parentId}`, customer);
    }

    addWorkspace(model:WorkspaceCreate): Observable<Customer> {
        return this.http.post(`${this.apiEndpoint}/workspace`, model);
    }

    updateCustomer(customer: Customer): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${customer.id}`, customer);
    }

    getUsers(customerId: string): Observable<User[]> {
        return this.http.get(`${this.apiEndpoint}/${customerId}/user`);
    }
    addUser(customerId: string, user: UserForm): Observable<User> {
        return this.http.post(`${this.apiEndpoint}/${customerId}/user`, user);
    }
    updateUser(customerId: string, user: UserForm): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${customerId}/user/${user.id}`, user);
    }
    removeUser(customerId: string, userId: number): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${customerId}/user/${userId}`);
    }

    deleteWorkspace(id: string): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${id}`);
    }
}
