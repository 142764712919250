import {Observable} from 'rxjs';
import {BaseData} from './base';
import {DeviceInfo} from "./devices";
import {BaseInfo} from "../../common";

export interface ErrorLog {
    id: number;
    errorType: ErrorType;
    errorSubType: string;
    message: string;
    enterTimestamp: string;
    leaveTimestamp: string;
    acknowledgeTimestamp: string;
    acknowledgeComment: string;
    additionalInfo: string;
    acknowledgeUser: BaseInfo<number>;
    deviceId: number;
    device: DeviceInfo;
}

export abstract class ErrorLogData extends BaseData<ErrorLog, number> {
    abstract getFilteredByDeviceId(deviceId: number, active: boolean, unacknowledged: boolean, from: string | null, to: string | null): Observable<ErrorLog[]>;

    abstract getFilteredByMeasuringPointId(measuringPointId: string, active: boolean, unacknowledged: boolean, from: string | null, to: string | null): Observable<ErrorLog[]>;

    abstract getActive(): Observable<ErrorLog[]>;

    abstract getErrorLogs(from: string, to: string): Observable<ErrorLog[]>;

    abstract acknowledge(id: number, comment: string): Observable<ErrorLog>;

    abstract acknowledgeDeviceErrors(deviceId: number, comment: string): Observable<boolean>;

    abstract acknowledgeMeasuringPointErrors(measuringPointId: string, comment: string): Observable<boolean>;

}

export enum ErrorType {
    None = 0,
    NoCommunication = 1,
    WrongCommunicationInterval = 2,
    WrongTimestamp = 4,
    BatteryWarning = 512,
    LowBattery = 8,
    IncompleteMessages = 16,
    ErrorDeviceLogs = 32,
    SimDataExceeded = 64,
    SmsAmountWarning = 128,
    SmsAmountExceeded = 256
}
