import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ErrorLog, ErrorLogData} from '../model/error-log';
import {BaseService} from './base.service';
import {DeviceHttpService} from '../device-http.service';

@Injectable()
export class ErrorLogService extends BaseService implements ErrorLogData {
    get apiEndpoint(): string {
        return 'errorlog';
    }

    constructor(http: DeviceHttpService) {
        super(http);
    }

    getFilteredByDeviceId(deviceId: number, active: boolean, unacknowledged: boolean, from: string | null, to: string | null):
        Observable<ErrorLog[]> {
        const deviceFilter = deviceId >= 0 ? `/device/${deviceId}` : ''
        let url = `${this.apiEndpoint}${deviceFilter}?active=${active}&unacknowledged=${unacknowledged}`;
        if (from) {
            url = `${url}&from=${from}`;
        }
        if (to) {
            url = `${url}&to=${to}`;
        }
        return this.http.get(url);
    }

    getFilteredByMeasuringPointId(measuringPointId: string, active: boolean, unacknowledged: boolean, from: string | null, to: string | null):
        Observable<ErrorLog[]> {
        let url = `${this.apiEndpoint}/measuringPoint/${measuringPointId}?active=${active}&unacknowledged=${unacknowledged}`;
        if (from) {
            url = `${url}&from=${from}`;
        }
        if (to) {
            url = `${url}&to=${to}`;
        }
        return this.http.get(url);
    }

    getActive(): Observable<ErrorLog[]> {
        return this.http.get(`${this.apiEndpoint}?active=true`);
    }

    getErrorLogs(from: string, to: string): Observable<ErrorLog[]> {
        return this.http.get(`${this.apiEndpoint}?from=${from}&to=${to}`);
    }

    acknowledge(id: number, comment: string): Observable<ErrorLog> {
        return this.http.put(`${this.apiEndpoint}/${id}/acknowledge`, {comment});
    }

    acknowledgeDeviceErrors(deviceId: number, comment: string): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/device/${deviceId}/acknowledge`, {comment});
    }

    acknowledgeMeasuringPointErrors(measuringPointId: string, comment: string): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/measuringPoint/${measuringPointId}/acknowledge`, {comment});
    }
}
