import {Base} from '../../common';
import {CustomerInfo} from './customers';
import {BaseData} from './base';
import {Observable} from 'rxjs';
import {DeviceInfo} from './devices';
import {UserInfo} from './users';

export interface Rental extends Base<number> {
    start: string;
    end: string;
    customer: CustomerInfo;
    customerId: string;
    devices: RentalDevice[];
    requirements: RentalRequirement[];
    reminder: boolean;
    users: UserInfo[];
    status: RentalStatus,
    renter: CustomerInfo,
    renterId: string
}

export interface RentalDevice {
    deviceId: number;
    device?: DeviceInfo;
    start: string;
    end: string;
    id?: number;
}

export interface RentalRequirement {
    articlePrefix: string;
    amount: number;
    id?: number;
}
export interface RentalCreate {
    name: string;
    start: string;
    end:string;
    customerId: string;
    rentalDevices?:RentalDevice[];
    rentalRequirements?:RentalRequirement[];
    reminder: boolean;
    renterId: string;
}

export enum RentalStatus {
    pending = 'Pending',
    active = 'Active',
    stopped = 'Stopped'
}


export abstract class RentalData extends BaseData<Rental, number> {
    abstract getRentals(from: string | null, to: string | null): Observable<Rental[]>;
    abstract getRental(id: number): Observable<Rental>;
    abstract createRental(create: RentalCreate): Observable<Rental>;
    abstract updateRental(rental: Rental): Observable<boolean>;
    abstract deleteRental(id: number): Observable<boolean>;
    abstract getByDeviceid(deviceId: number): Observable<Rental[]>;
    abstract addDevices(id: number, requirementId: number): Observable<boolean>;
    abstract getDevicesAvailable(id: number, requirementId: number): Observable<DeviceInfo[]>;
    abstract getUsersAvailable(id: number) : Observable<UserInfo[]>;
    abstract addDevicesToPool(deviceIds: number[]): Observable<boolean>;
    abstract removeDeviceFromPool(deviceId: number): Observable<boolean>;
    abstract startRental(id: number, createProject: boolean, projectName: string): Observable<boolean>;
    abstract stopRental(id: number, additionalInfo: boolean): Observable<boolean>;
}

