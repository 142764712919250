import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {License, LicenseData, LicenseType} from '../model/licenses';
import {DeviceHttpService} from '../device-http.service';
import {BaseService} from './base.service';
import {DeviceType} from '../model/devices';

@Injectable()
export class LicenseService extends BaseService implements LicenseData {
    get apiEndpoint(): string {
        return 'license';
    }

    constructor(http: DeviceHttpService) {
        super(http);
    }

    getLicenses(): Observable<License[]> {
        return this.http.get(`${this.apiEndpoint}`);
    }

    getById(id: number): Observable<License> {
        return this.http.get(`${this.apiEndpoint}/${id}`);
    }

    getByCustomerId(customerId: string): Observable<License[]> {
        return this.http.get(`${this.apiEndpoint}?customerId=${customerId}`);
    }

    getUnAssignedProjectLicenses(customerId: string): Observable<License[]> {
        return this.http.get(`${this.apiEndpoint}/project?customerId=${customerId}`);
    }

    getUnAssignedMeasuringPointLicenses(customerId: string, articles: string[]): Observable<License[]> {
        if(articles === null) {
            return this.http.get(`${this.apiEndpoint}/measuringPoint?customerId=${customerId}`);
        }
        return this.http.get(`${this.apiEndpoint}/measuringPoint?customerId=${customerId}&articles=${articles}`);
    }

    getUnAssignedDeviceLicenses(customerId: string, articles: string[] | null, deviceType: DeviceType): Observable<License[]> {
        if(articles === null) {
            return this.http.get(`${this.apiEndpoint}/device?customerId=${customerId}&deviceType=${deviceType}`);
        }
        return this.http.get(`${this.apiEndpoint}/device?customerId=${customerId}&deviceType=${deviceType}&articles=${articles}`);
    }

    linkLicenseToCustomer(id: number, customerId: string, additionalInfo: string): Observable<boolean> {
        const formData = new FormData();
        formData.set('additionalInfo', additionalInfo);
        return this.http.put(`${this.apiEndpoint}/${id}/link/${customerId}`, formData);
    }

    rentLicenseToCustomer(id: number, customerId: string, additionalInfo: string): Observable<boolean> {
        const formData = new FormData();
        formData.set('additionalInfo', additionalInfo);
        return this.http.put(`${this.apiEndpoint}/${id}/rent/${customerId}`, formData);
    }

    returnLicenseToOwner(licenseId: number): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${licenseId}/rent`);
    }

    getByDeviceId(id: number): Observable<License[]> {
        return this.http.get(`${this.apiEndpoint}/device/${id}`);
    }

    getByNicosStructureId(id: string): Observable<License[]> {
        return this.http.get(`${this.apiEndpoint}/nicosStructure/${id}`);
    }

    assignLicense(token: string, identifier: string): Observable<boolean> {
        const formData = new FormData();
        formData.set('identifier', identifier);
        return this.http.put(`${this.apiEndpoint}/token/${token}`, formData);
    }

    unAssignLicense(token: string, deactivateSim: boolean = true): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/token/${token}?additionalInfo=${deactivateSim}`);
    }

    getLicenseTypeByArticle(article: string): LicenseType {
        switch (article) {
            case  'NCD0LIZENZMQTT':
            case  'NFM0LIZENZERW':
            case  'NFM0LIZENZEXP':
            case  'NFM0LIZENZFTP':
            case  'NFS0LIZENZGEO':
            case  'NFX0LIZENZERW':
            case  'NFX0LIZENZEXP':
            case  'NFX0LIZENZFTP':
            case  'NFX0LIZENZHART':
            case  'NFX0LIZENZMODB':
            case  'NFX0LIZENZPWDN':
            case  'NFX0LIZENZREGL':
            case  'NFX0LIZENZSOFF':
            case  'SNI0CONNECT00':
            case  'SNI0CONNECT01':
            case  'SNI0CONNECT02':
            case  'SNI0CONNECT03':
            case  'SNI0DKSREMOTEAC':
                return LicenseType.Device;
            case  'SNI0DKSDATAMS':
            case  'SNI0WEBMESSSTEL':
            case   'SNI0WEBPBERI':
            case   'SNI0WEBPGRAPH':
            case   'SNI0WEBPMAIL':
            case   'SNI0WEBPORTAL':
            case   'SNI0WEBPSMS':
            case   'SNI0WEBPVISU':
                return LicenseType.MeasuringPoint;
            default:
                return LicenseType.Project;
        }
    }

    isConnectLicense(article: string): boolean {
        return article === 'SNI0CONNECT01' || article === 'SNI0CONNECT02' || article === 'SNI0CONNECT03';
    }
}
