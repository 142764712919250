import {Component} from '@angular/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from "../../../shared/auth/auth.service";

@Component({
    selector: 'nivus-vertical-header',
    templateUrl: './vertical-header.component.html',
    styleUrls: []
})

export class VerticalHeaderComponent {
    public config: PerfectScrollbarConfigInterface = {};

    public selectedLanguage: any = {
        language: 'GERMAN',
        code: 'de',
        icon: 'de'
    }

    public languages: any[] = [{
        language: 'ENGLISH',
        code: 'en',
        type: 'US',
        icon: 'us'
    },
    {
        language: 'FRENCH',
        code: 'fr',
        icon: 'fr'
    },
    {
        language: 'GERMAN',
        code: 'de',
        icon: 'de'
    }];


    constructor(private translate: TranslateService, public authService: AuthService) {
        this.translate.onLangChange.subscribe(event => this.highlightLanguage(event.lang));
        this.highlightLanguage(this.translate.currentLang ?? this.translate.defaultLang);
    }

    highlightLanguage(lang: string) {
        this.selectedLanguage = this.languages.find(e => e.code === lang);
    }

    changeLanguage(lang: any) {
        this.translate.use(lang.code);
    }
}
