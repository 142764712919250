export const environment = {
    production: false,
    apiUrl: 'https://devices-api.nivusweb.com/api',
    webportalUrl: 'https://portal.nivusweb.com/cloud',
    dataKioskUrl: 'https://datakiosk.nivusweb.com',
    dateTimeFormat: 'MM/dd/yyyy, HH:mm:ss (O)',
    dateFormat: 'MM/dd/yyyy',
    version: 'Dev',
    emailPattern: '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
};
