import {NicosStructureService} from './service/nicosStructure.service';
import {NicosStructureData} from 'src/app/shared/backend/device/model/nicosStructure';
import {LicenseService} from './service/license.service';
import {DevicesService} from '../device/service/devices.service';
import {CommonModule} from '@angular/common';
import {NgModule, ModuleWithProviders} from '@angular/core';
import {DeviceData} from './model/devices';
import {DeviceHttpService} from './device-http.service';
import {CustomersData} from './model/customers';
import {CustomersService} from './service/customers.service';
import {UsersData} from './model/users';
import {UsersService} from './service/users.service';
import {LicenseData} from './model/licenses';
import {TemplateData} from './model/template';
import {TemplateService} from './service/templates.service';
import {SimData} from './model/simcards';
import {SimService} from './service/sim.service';
import {ErrorLogData} from './model/error-log';
import {ErrorLogService} from './service/error-log.service';
import {ErrorStateData} from './model/error-state';
import {ErrorStateService} from './service/error-state.service';
import {ErrorStateConfigService} from './service/error-state-config.service';
import {ErrorStateConfigData} from './model/error-state-config';
import {StatisticsData} from './model/statistics';
import {StatisticsService} from './service/statistics.service';
import {FileData} from './model/files';
import {FilesService} from './service/files.service';
import {TranslationsData} from "./model/translations";
import {TranslationsService} from "./service/translations.service";
import {RentalData} from './model/rentals';
import {RentalsService} from './service/rentals.service';
import {ErpData} from './model/erp';
import {ErpService} from './service/erp.service';

const API = [
    DeviceHttpService
];

const SERVICES = [
    {provide: DeviceData, useClass: DevicesService},
    {provide: CustomersData, useClass: CustomersService},
    {provide: UsersData, useClass: UsersService},
    {provide: LicenseData, useClass: LicenseService},
    {provide: NicosStructureData, useClass: NicosStructureService},
    {provide: TemplateData, useClass: TemplateService},
    {provide: SimData, useClass: SimService},
    {provide: ErrorLogData, useClass: ErrorLogService},
    {provide: ErrorStateData, useClass: ErrorStateService},
    {provide: ErrorStateConfigData, useClass: ErrorStateConfigService},
    {provide: StatisticsData, useClass: StatisticsService},
    {provide: FileData, useClass: FilesService},
    {provide: TranslationsData, useClass: TranslationsService},
    {provide: RentalData, useClass: RentalsService},
    {provide: ErpData, useClass: ErpService}
];

@NgModule({
    imports: [CommonModule],
})
export class DeviceBackendModule {
    static forRoot(): ModuleWithProviders<DeviceBackendModule> {
        return {
            ngModule: DeviceBackendModule,
            providers: [
                ...API,
                ...SERVICES,
            ],
        };
    }
}
