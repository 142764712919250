import {Observable} from 'rxjs';
import {Base} from '../../common';
import {BaseData} from './base';

export interface SimCard extends Base<string> {
    imsi: string;
    msisdn: string;
    assignedImei: string;
    assignedIp: string;
    activated: Date;
    dataCurrentMonth: number;
    tariff: string;
    state: SimState;
    providerType: SimProviderType;
    device: number;
}

export interface Tariff {
    solution: string;
    providerType: SimProviderType;
    imsiPrefix: string;
}

export enum SimState {
    inactive = 'Inactive',
    test = 'Test',
    active = 'Active',
    barred = 'Barred',
    cancelled = 'Cancelled',
    stopped = 'Stopped'
}

export enum SimProviderType {
    wirelessLogic = 'WirelessLogic',
    vodafone = 'Vodafone',
    none = '-'
}

export abstract class SimData extends BaseData<SimCard, string> {
    abstract getSimCards(): Observable<SimCard[]>;

    abstract getSimCard(iccid: string): Observable<SimCard>;

    abstract getTariffs(): Observable<Tariff[]>;

    abstract activate(iccid: string, tariff: string): Observable<boolean>;

    abstract deactivate(iccid: string): Observable<boolean>;

    abstract reload(iccid: string): Observable<SimCard>;

    abstract wakeUp(iccid: string): Observable<boolean>;
}




