import {Observable} from 'rxjs';
import {BaseData} from './base';

export interface ErrorState {
    id: number;
    deviceId: number;
    projectId: string;
    customerId: string;
    errorStateType: ErrorStateType;
    activeErrorTypes: number;
}

export abstract class ErrorStateData extends BaseData<ErrorState, number> {
    abstract getAll(): Observable<ErrorState[]>;
    abstract getAllCached(): Observable<ErrorState[]>;

    abstract reload(): void;

    abstract getFiltered(projectId: string): Observable<ErrorState[]>;

    abstract recalculateErrorStates(): Observable<boolean>;

    abstract recalculateErrorStatesByProjectId(projectId: string): Observable<boolean>;

    abstract getBgClass(state: ErrorStateType): string;

    abstract getIcon(state: ErrorStateType): string;
}

export enum ErrorStateType {
    ok = 0,
    warning = 1,
    error = 2
}
