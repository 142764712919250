import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {Direction} from "@angular/cdk/bidi";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {getCookie, setCookie} from 'typescript-cookie'
import {TranslateService} from "@ngx-translate/core";

/** @title Responsive sidenav */
@Component({
    selector: 'nivus-full-layout',
    templateUrl: 'full.component.html',
    styleUrls: ['full.component.scss']
})
export class FullComponent implements OnInit {
    isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall])
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    dir: Direction = 'ltr';
    boxed = false;
    url = '';
    sidebarOpened = false;
    minisidebar = true;

    version = environment.version;
    hideFrame: boolean;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService
    ) {
        this.minisidebar = getCookie('Sidebar') !== 'false';
        setCookie('Sidebar', this.minisidebar, {expires: 365, path: '/'});
    }

    toggleMinisidebar() {
        this.minisidebar = !this.minisidebar;
        setCookie('Sidebar', this.minisidebar, {expires: 365, path: '/'});
    }

    ngOnInit(): void {
        const firstChild = this.activatedRoute.snapshot.firstChild;
        this.hideFrame = firstChild?.data.hideFrame === true;
        this.translate.setDefaultLang(firstChild?.queryParams.lang ?? 'de');
    }
}
